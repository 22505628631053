import { Application, Graphics, Sprite, Texture, Container, Loader, utils } from "./custom-pixi-js";

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

utils.skipHello();

// duration in ms a snowfall takes to fall from top to bottom
const minDuration = 8000;
const maxDuration = 14000;

const maxOscillationFrequency = 9;
const maxOscillationAmplitude = 45;

const maxRotations = 360 * 3;

// pixel per ms
const snowmanSpeed = 25 / 1000;

const numSnowflakes = (width: number) => Math.max(Math.min(0.02 * width, 50), 10);
const maxFlakeSize = 32;

const sledSpeed = 0.0035;

const minSledDelay = 15000;
const maxSledDelay = 60000;

const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
const header = document.querySelector("#Header");
const mainHeader = document.querySelector("#Main .home-header-section");

const filePath = (fileName) => `/assets/specials/christmas/${fileName}`;

type TextureObj = {
    path: string;
    size: { width: number; height: number };
    texture: Texture | null;
};

const getTextureObj = (path, size): TextureObj => ({
    path,
    size,
    texture: null,
});

const textures = {
    snowflakes: [
        getTextureObj(filePath("flake1.png"), { width: 128, height: 128 }),
        getTextureObj(filePath("flake2.png"), { width: 128, height: 128 }),
    ],
    snowman: getTextureObj(filePath("snowman.png"), { width: 256, height: 256 }),
    hat: getTextureObj(filePath("hat.png"), { width: 128, height: 128 }),
    sled: getTextureObj(filePath("sled.png"), { width: 256, height: 256 }),
    house: getTextureObj(filePath("house.png"), { width: 256, height: 256 }),
};

const splines = {
    snowhills: [
        (width, height) => ({
            color: 0xfafafa,
            start: [-2, height + 1],
            // path: [0.1 * width, 0.85 * height, 0.35 * width, 0.85 * height, 0.5 * width, height + 1]
            path: [
                0.1 * width,
                Math.max(height - 0.09 * width, 0.85 * height),
                0.35 * width,
                Math.max(height - 0.09 * width, 0.85 * height),
                0.5 * width,
                height + 1,
            ],
        }),
        (width, height) => ({
            color: 0xffffff,
            start: [-2, height + 1],
            // path: [0.5 * width, height + 1, 0.66 * width, 0.75 * height, width + 1, height + 1]
            path: [
                0.5 * width,
                height + 1,
                0.66 * width,
                Math.max(height - 0.2 * width, 0.75 * height),
                width + 1,
                height + 1,
            ],
        }),
    ],
};

const snowhillSplinesCache: number[][] = splines.snowhills.map((s) => s(0, 0).path);

const secondaryColor = 0x212c3e;

let canvas: HTMLCanvasElement;
let width: number;
let height: number;

const initCanvas = () => {
    canvas = document.createElement("canvas");
    canvas.id = "ChristmasCanvas";
    document.body.appendChild(canvas);
    document.body.classList.add("enable-christmas-gimmick");
    updateCanvas();
};

const updateCanvas = () => {
    const headerRect = header.getBoundingClientRect();
    const mainHeaderRect = mainHeader.getBoundingClientRect();

    width = mainHeaderRect.width;
    height = headerRect.height + mainHeaderRect.height;

    canvas.style.width = `${width}px`;
    canvas.style.height = `${height}px`;

    canvas.width = width;
    canvas.height = height;
};

let app: Application;

let bgContainer = new Container();
let fgContainer = new Container();

type Snowflake = {
    instance: Sprite;
    targetDuration: number;
    currentDuration: number;
    x: number;
    scale: number;
    rotation: number;
};

let snowflakes: Snowflake[] = [];
let snowhills: Graphics[] = [];
let snowman: Sprite;
let hat: Sprite;

let sled: Sprite;
let sledActive = false;
let sledProgress = 0;

let house: Sprite;

let texturesLoaded = false;

const getAbsoluteYPos = (percentage: number) => {
    return percentage * (height + maxFlakeSize) - maxFlakeSize;
};

const getAbsoluteXPos = (percentage: number) => {
    return percentage * width;
};

const resetFlake = (flake: Snowflake, initial = false): Snowflake => {
    flake.targetDuration = Math.floor(Math.random() * (maxDuration - minDuration)) + minDuration;
    flake.currentDuration = initial ? Math.random() * flake.targetDuration : 0;
    flake.x = Math.random();
    flake.scale = Math.max(Math.random(), 0.5);
    flake.rotation = Math.random() - 0.5;
    return flake;
};

const createFlake = (texture: Texture) => {
    const instance = Sprite.from(texture);
    instance.width = maxFlakeSize;
    instance.height = maxFlakeSize;
    instance.anchor.set(0.5);
    return resetFlake(
        {
            instance,
            targetDuration: 0,
            currentDuration: 0,
            x: 0,
            scale: 0,
            rotation: 0,
        },
        true
    );
};

const getPosOnBezier = (t, sx, sy, cp1x, cp1y, cp2x, cp2y, ex, ey) => {
    return {
        x: Math.pow(1 - t, 3) * sx + 3 * t * Math.pow(1 - t, 2) * cp1x + 3 * t * t * (1 - t) * cp2x + t * t * t * ex,
        y: Math.pow(1 - t, 3) * sy + 3 * t * Math.pow(1 - t, 2) * cp1y + 3 * t * t * (1 - t) * cp2y + t * t * t * ey,
    };
};

const getAngleOnBezier = (t, sx, sy, cp1x, cp1y, cp2x, cp2y, ex, ey) => {
    let dx = Math.pow(1 - t, 2) * (cp1x - sx) + 2 * t * (1 - t) * (cp2x - cp1x) + t * t * (ex - cp2x);
    let dy = Math.pow(1 - t, 2) * (cp1y - sy) + 2 * t * (1 - t) * (cp2y - cp1y) + t * t * (ey - cp2y);
    return -Math.atan2(dx, dy) + 0.5 * Math.PI;
};

const startSledTimer = (delay: number = null) => {
    sledActive = false;
    sled.visible = false;
    let timeout = delay ? delay : Math.random() * (maxSledDelay - minSledDelay) + minSledDelay;

    window.setTimeout(() => {
        sledActive = true;
        sled.visible = true;
        sledProgress = 1;
    }, timeout);
};

// everything that needs to be updated every frame
const update = () => {
    if (!texturesLoaded) return;

    // snowflakes
    snowflakes.forEach((flake: Snowflake) => {
        flake.currentDuration += app.ticker.deltaMS;

        const progress = Math.min(flake.currentDuration / flake.targetDuration, 1);

        const x =
            getAbsoluteXPos(flake.x) +
            Math.sin(progress * maxOscillationFrequency) * (flake.scale * maxOscillationAmplitude);
        const y = getAbsoluteYPos(progress);
        const rotation = progress * (flake.rotation * maxRotations);
        const scale = flake.scale;
        const size = maxFlakeSize * scale;

        flake.instance.x = x;
        flake.instance.y = y;
        flake.instance.angle = rotation;
        flake.instance.width = size;
        flake.instance.height = size;

        if (flake.currentDuration >= flake.targetDuration) {
            resetFlake(flake);
        }
    });

    // sled
    if (sledActive) {
        sledProgress -= sledSpeed * app.ticker.deltaTime;

        if (sledProgress < 0) {
            startSledTimer();
        } else {
            const path = snowhillSplinesCache[1];
            // @ts-ignore
            let pos = getPosOnBezier(sledProgress, -32, height, ...path);
            // @ts-ignore
            let angle = getAngleOnBezier(sledProgress, -32, height, ...path);

            sled.position.set(pos.x, pos.y);
            sled.rotation = angle;
        }
    }
};

// everything that needs to be done once on size change
const resize = () => {
    app.resizeTo = canvas;
    app.resize();

    // snowhills
    snowhills.forEach((g, i) => {
        const data = splines.snowhills[i](width, height);
        snowhillSplinesCache[i] = data.path;

        g.clear();
        g.beginFill(data.color);
        g.moveTo(data.start[0], data.start[1]);
        // @ts-ignore
        g.bezierCurveTo(...(data.path as number[]));
        g.closePath();
    });

    if (!texturesLoaded) return;

    updateSnowflakeNumbers();

    // @ts-ignore
    const housePos = getPosOnBezier(0.5, 0, height, ...snowhillSplinesCache[0]);
    house.position.set(housePos.x, housePos.y);
    const houseSize = Math.min(width * 0.15, 96);
    house.width = houseSize;
    house.height = houseSize;

    // sled
    const sledSize = Math.min(width * 0.175, 96);
    sled.width = sledSize;
    sled.height = sledSize;

    // @ts-ignore
    const snowmanPos = getPosOnBezier(0.7, 0, height, ...snowhillSplinesCache[1]);
    const snowmanSize = Math.max(Math.min(width * 0.25, 128), 96);
    snowman.position.set(snowmanPos.x, snowmanPos.y + snowmanSize * 0.2);
    snowman.width = snowmanSize;
    snowman.height = snowmanSize;

    const hatSize = Math.max(Math.min(width * 0.11, 64), 32);
    hat.position.set(snowmanPos.x, snowmanPos.y - snowmanSize * 0.63);
    hat.width = hatSize;
    hat.height = hatSize;
    hat.rotation = 25;
};

const updateSnowflakeNumbers = () => {
    const targetNumber = numSnowflakes(width);
    const currentNumber = snowflakes.length;

    if (targetNumber === currentNumber) return;

    const addFlakes = targetNumber > currentNumber;

    for (let i = 0; i < Math.abs(targetNumber - currentNumber); i++) {
        if (addFlakes) {
            const flake = createFlake(textures.snowflakes[i % textures.snowflakes.length].texture);
            snowflakes.push(flake);
            bgContainer.addChild(flake.instance);
        } else {
            const flake = snowflakes.pop();
            app.stage.removeChild(flake.instance);
            flake.instance.destroy();
        }
    }
};

const initApp = () => {
    app = new Application({
        width,
        height,
        view: canvas,
        backgroundColor: secondaryColor,
        antialias: true,
        resolution: window.devicePixelRatio,
    });

    app.stage.addChild(bgContainer);
    app.stage.addChild(fgContainer);

    // snowhills
    snowhills = splines.snowhills.map((_) => new Graphics());
    snowhills.forEach((s) => fgContainer.addChild(s));

    const loader = Loader.shared;
    textures.snowflakes.forEach((flake, i) => loader.add(`flake_${i}`, flake.path));
    loader.add("house", textures.house.path);
    loader.add("sled", textures.sled.path);
    loader.add("hat", textures.hat.path);
    loader.add("snowman", textures.snowman.path);

    loader.load((_, resources) => {
        // @ts-ignore
        textures.snowflakes.forEach((flake, i) => (flake.texture = resources[`flake_${i}`].texture));

        textures.house.texture = resources.house.texture;
        textures.sled.texture = resources.sled.texture;
        textures.hat.texture = resources.hat.texture;
        textures.snowman.texture = resources.snowman.texture;

        texturesLoaded = true;

        // house
        house = Sprite.from(textures.house.texture);
        house.anchor.set(0.5, 0.8);
        fgContainer.addChild(house);

        // sled
        sled = Sprite.from(textures.sled.texture);
        sled.anchor.set(0.5, 0.85);
        fgContainer.addChild(sled);

        // snowman
        snowman = Sprite.from(textures.snowman.texture);
        snowman.anchor.set(0.5, 1);
        fgContainer.addChild(snowman);

        // hat
        hat = Sprite.from(textures.hat.texture);
        hat.anchor.set(0.5, 1);
        fgContainer.addChild(hat);

        resize();

        startSledTimer(3000);
    });

    resize();

    app.ticker.add(update);

    window.addEventListener("resize", () => {
        updateCanvas();
        resize();
    });
};

// start the gimmick
if (mainHeader && mediaQuery && !mediaQuery.matches) {
    initCanvas();
    initApp();
}
